export const updateState = () => {
  return {
    type: "UPDATE_STATE",
  };
};

export const resetState = () => {
  return {
    type: "RESET_STATE",
  };
};

export const updateAccessStatus = data => {
  return {
    type: "UPDATE_ACCESS_STATUS",
    data
  };
};

export const updateCookieStatus = data => {
  return {
    type: "UPDATE_COOKIE_STATUS",
    data
  };
};

export const updateUser = data => {
  return {
    type: "UPDATE_USER",
    data
  };
};

export const updatePlan = data => {
  return {
    type: "UPDATE_PLAN",
    data
  };
};

export const updateLimits = data => {
  return {
    type: "UPDATE_LIMITS",
    data
  };
};

export const updateLimitsIntegrations = data => {
  return {
    type: "UPDATE_LIMITS_INTEGRATIONS",
    data
  };
};

export const updateAccountIntegrations = data => {
    return {
        type: "UPDATE_ACCOUNT_INTEGRATIONS",
        data
    };
};

export const updateRoles = data => {
  return {
    type: "UPDATE_ROLES",
    data
  };
};

export const updateLayouts = data => {
  return {
    type: "UPDATE_LAYOUTS",
    data
  };
};

export const updateProjects = data => {
  return {
    type: "UPDATE_PROJECTS",
    data
  };
};

export const updateActualProject = data => {
  return {
    type: "UPDATE_ACTUAL_PROJECT",
    data
  };
};

export const updateActualLayout = data => {
  return {
    type: "UPDATE_ACTUAL_LAYOUT",
    data
  };
};

export const updateSending = data => {
  return {
    type: "UPDATE_SENDING",
    data
  };
};

export const updateDeleting = data => {
  return {
    type: "UPDATE_DELETING",
    data
  };
};

export const updateFilters = data => {
  return {
    type: "UPDATE_FILTERS",
    data
  };
};

export const updateCampaigns = data => {
  return {
    type: "UPDATE_CAMPAIGNS",
    data
  };
};

export const updateInitApp = data => {
  return {
    type: "UPDATE_INIT_APP",
    data
  };
};

export const updateEmpty = data => {
  return {
    type: "UPDATE_EMPTY",
    data
  };
};

export const showNotify = data => {
  return {
    type: "SHOW_NOTIFY",
    data
  };
};

export const hideNotify = data => {
  return {
    type: "HIDE_NOTIFY",
    data
  };
};

export const updateEntity = data => {
  return {
    type: "UPDATE_ENTITY",
    data
  };
};