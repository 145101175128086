// React
import React, { Component } from "react";
import { connect } from "react-redux";
// Routing
import { withRouter } from "react-router-dom";
// Infrastructure
// Application
import { ElementsHandleV2 } from "vendor/application/handleMethodsV2";
// Vendor
import {
  handlePhrasesParams,
  isJsonString,
  isPlanAllowed,
  returnElementFromArray,
} from "vendor/application";
// Material UI
import { CircularProgress, Divider, Switch } from "@material-ui/core";
// Components
import { Button, Input } from "@components/Input";
import Tooltip from "@components/Tooltip";
import AccordionIntents from "components/Accordion/AccordionIntents";
import { ContextsAPI } from "views/Bots/NLP/application/Build/Tabs/Contexts/infrastructure";
import { IntentsAPI } from "views/Bots/NLP/application/Build/Tabs/Intents/infrastructure";
import Integrations from "views/Bots/NLP/application/Build/Tabs/Intents/application/Integrations";
import { IntegrationsAPI } from "api/application/Integrations";
import { PopUp } from "@components/Modals";
// Translations
import { withTranslation } from "react-i18next";
import { showNotify } from "vendor/application/disptach";
// Infrastructure
import { get_users } from "api/application/Users";
// Styles
import "../styles/index.scss";
// Assets
import { cloneSvg } from "@assets";
// Amplitude API
import { AmplitudeAPI } from "@api/Amplitude";
import { parseVariablesDeskEdit } from "./utils/parseVariablesEdit";

import { ElementsCustom } from "@components/Elements";
import { validExtensions } from "./utils/validExtensions";

class IntentEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contexts: "",
      intent: "",
      integrations: [],
      clone_name: "",
      limits: props.data.limits,
      params: "",
      users: [],
      variables: [],
      events: [],
      zendesk_full: "",
      zendesk_groups: false,
      open: false,
      settings: {
        tags: "",
        tags_hours_control: "",
        tags_individual: "",
        tags_campaign: "",
      },

      zendesk: "",
    };
  }

  componentDidMount = () => {
    this.initServices();
  };

  initServices = async () => {
    this.handleAmplitude("Enter to existing intent");
    this.getIntent();
    this.getContexts();
    this.getParams();
  };

  handleAmplitude = (event_type) => {
    const { user, device_id } = this.props.data;

    new AmplitudeAPI({
      event_type: event_type,
      device_id: device_id,
      user_id: user.email,
    }).eventLog();
  };

  getIntent = async () => {
    const { actualProject, access } = this.props.data;
    try {
      let obj = new IntentsAPI(actualProject.id, access, this.props);
      let intent = await obj.get_intent_by_id();

      this.getEvents(intent.id);

      var info = intent?.desks?.info;

      if (!info) {
        intent.desks = {
          info: {
            ticket_status: "new",
            zendesk_ticket_status: "new",
            zendesk_mode: "desk",
            centridesk_ticket_status: "new",
            centridesk_mode: "desk",
            group_id: null,
            max_tokens: 16,
            max_interactions: 1,
            desk_id: null,
          },
        };
      } else {
        var channel = intent.desks.name,
          status = intent.desks.info.ticket_status,
          mode = intent.desks.info.mode ?? "desk";

        intent.desks.info.zendesk_mode = "desk";
        intent.desks.info.centridesk_mode = "desk";
        intent.desks.info.zendesk_ticket_status = "new";
        intent.desks.info.centridesk_ticket_status = "new";

        if (!intent.desks.info.max_tokens) {
          intent.desks.info.max_tokens = 16;
          intent.desks.info.max_interactions = 1;
        }

        if (channel === "zendesk_full") {
          intent.desks.info.zendesk_ticket_status = status;
          intent.desks.info.zendesk_mode = mode;
        } else if (channel === "centridesk") {
          intent.desks.info.centridesk_ticket_status = status;
          intent.desks.info.centridesk_mode = mode;
        }
      }

      if (intent.extensions.auth) {
        if (!intent.extensions.auth?.headers) {
          intent.extensions.auth.headers = [];
        }
        intent.extensions.auth.access_key_input =
          intent.extensions.auth.access_key?.replaceAll("$.", "");
      }

      if (intent.conditional_responses) {
        intent.conditional_responses.forEach((conditional, i) => {
          if (!conditional.param0 && conditional.param1) {
            if (["$.sys_is_in_business_hours"].includes(conditional.param1)) {
              intent.conditional_responses[i].param0 =
                "$.sys_is_in_business_hours";
            } else {
              intent.conditional_responses[i].param0 = "custom";
            }
          }
        });
      }

      this.setState({ intent: intent });

      this.handleVariables(intent);
      this.getIntegrations(intent);
    } catch (err) {
      this.props.dispatch(showNotify({ message: err, severity: "error" }));

      this.returnToList();
    }
  };

  getEvents = async (intent_id) => {
    const { access, actualProject } = this.props.data;
    try {
      const obj = new IntentsAPI(actualProject.id, access, this.props);
      const events = await obj.get_events();
      var events_arr = [];
      events.forEach((event) => {
        var new_events = [];
        if (event.id !== intent_id) {
          event.events.forEach((evt) => {
            new_events.push({
              intent_id: event.id,
              event: evt,
            });
          });

          var group_events = {
            group_name: event.name,
            items: new_events,
          };

          events_arr.push(group_events);
        }
      });

      this.setState({ events: events_arr });
    } catch (err) {
      this.props.dispatch(showNotify({ message: err, severity: "error" }));
    }
  };

  handleVariables = (obj) => {
    const extension = obj.extensions;
    /**
     * @type {string[]}
     */
    const variables = extension?.variables || [];
    const equivalences = extension?.equivalences || {};
    /**
     * @type {import('types/zendesk').ZendeskVariable[]}
     */
    const variables_desk = extension.variables_desk || [];

    const ticketFieldByVariableName = new Map(
      (variables_desk || []).map((variable) => [
        variable.name,
        variable.zendesk_ticket_field,
      ])
    );
    /**
     * @type {import('types/ident').IdentVariable[]}
     */
    const newVariables = variables.map((element) => ({
      variable: element.substring(2),
      equivalence: equivalences[element]
        ? this.handleStartEquivalence(equivalences[element])
        : [],
      zendesk_ticket_field: ticketFieldByVariableName.get(element) || null,
    }));

    this.setState({
      variables: newVariables,
    });
  };

  handleStartEquivalence = (equivalences) => {
    var obj = [];

    for (var [key, value] of Object.entries(equivalences)) {
      obj.push({ key: key, value: value });
    }

    return obj;
  };

  getContexts = async () => {
    const { access, actualProject } = this.props.data;
    try {
      let obj = new ContextsAPI(actualProject.id, access, this.props);
      let contexts = await obj.get_contexts();
      this.setState({ contexts: contexts });
    } catch (err) {
      this.props.dispatch(showNotify({ message: err, severity: "error" }));
    }
  };

  getParams = async () => {
    const { access, actualProject } = this.props.data;
    try {
      const obj = new IntentsAPI(actualProject.id, access, this.props);
      const entities = await obj.get_entities();
      this.setState({ params: entities.custom.concat(entities.system) });
    } catch (err) {
      this.props.dispatch(showNotify({ message: err, severity: "error" }));
    }
  };

  getIntegrations = async (intent) => {
    const { access, actualProject, limitsIntegrations } = this.props.data;
    try {
      const obj = new IntegrationsAPI(actualProject.id, access, this.props);
      const res = await obj.get_integrations();
      var integrations = [];
      res.forEach((integration) => {
        var exist = integrations.filter(
          (int) => int.channel === integration.channel
        );
        if (
          [
            "zendesk_full",
            "zendesk_chat",
            "centridesk",
            "sunco",
            "gpt-3",
          ].includes(integration.channel) &&
          exist.length <= 0
        ) {
          if (limitsIntegrations[integration.channel]?.multiple) {
            var multiple = res.filter(
              (int) => int.channel === integration.channel
            );
            if (multiple.length > 0) {
              integration.multiple = [];
              multiple.forEach((m) =>
                integration.multiple.push({
                  id: m.id,
                  name: m.name ?? m.channel,
                  use_custom_fields: m.info.use_custom_fields,
                  is_default: m.is_default,
                })
              );
            }
          }
          integrations.push(integration);
        }
      });

      var switchboard = {
        updated: false,
        array: [],
      };

      var zendesk_full = integrations.filter(
          (integration) => integration.channel === "zendesk_full"
        )[0],
        sunco = integrations.filter(
          (integration) => integration.channel === "sunco"
        )[0];

      if (zendesk_full?.active) {
        if (intent.desks.name === "zendesk_full") {
          if (intent.desks.info.desk_id) {
            this.getGroups(intent.desks.info.desk_id);
          } else {
            if (zendesk_full.multiple.length > 1) {
              var is_default = zendesk_full.multiple.filter(
                (int) => int.is_default
              )[0];
              intent.desks.info.desk_id = is_default.id;
              this.getGroups(is_default.id);
            } else if (zendesk_full.multiple.length === 1) {
              intent.desks.info.desk_id = zendesk_full.multiple[0].id;
              this.getGroups(zendesk_full.multiple[0].id);
            }
          }
        }

        this.handleZendeskParameters(zendesk_full.multiple, intent);
      }

      if (sunco?.info?.zendesk?.use_custom_fields?.active) {
        this.getCustomFields("sunco", sunco.id, intent);
      }

      if (sunco?.active && sunco?.info?.switchboard.updated) {
        switchboard.updated = true;
        this.getSwitchboard(sunco);
      }

      var chatgpt = integrations.filter(
        (integration) => integration.channel === "gpt-3"
      )[0];
      if (chatgpt?.active) {
        intent.desks.info.max_tokens = chatgpt.info.max_tokens;
        intent.desks.info.max_interactions =
          chatgpt.info.back_to_bot.max_interactions;
      }

      var centridesk = integrations.filter(
        (integration) => integration.channel === "centridesk"
      )[0];
      if (centridesk) {
        this.getUsers();
      }

      this.setState({ integrations: integrations, switchboard: switchboard });
    } catch (err) {
      this.props.dispatch(showNotify({ message: err, severity: "error" }));
    }
  };

  getGroups = async (channel_id) => {
    const { access, actualProject } = this.props.data;
    try {
      const obj = new IntegrationsAPI(actualProject.id, access, this.props);
      var res = await obj.get_zendesk_groups(channel_id);
      this.setState({ zendesk_groups: res });
    } catch (err) {
      this.props.dispatch(showNotify({ message: err, severity: "error" }));
    }
  };

  getSwitchboard = async (sunco) => {
    const { actualProject, access } = this.props.data;
    try {
      const obj = new IntegrationsAPI(actualProject.id, access, this.props);
      const res = await obj.get_integration_switchboard_by_id(sunco.id);

      this.setState({ switchboard: { updated: true, array: res } });
    } catch (err) {
      this.props.dispatch(showNotify({ message: err, severity: "error" }));
    }
  };

  handleZendeskParameters = async (arr, intent) => {
    var custom_fields = arr?.filter((arr) => arr?.use_custom_fields);

    const customs = [];
    intent.parameters.forEach((param) => {
      var desk_id = param?.zendesk_ticket_field?.desk_id;
      if (desk_id && custom_fields.filter((el) => el.id === desk_id)[0]) {
        customs.push(desk_id);
      }
    });

    const channels_id = [...new Set(customs)];

    const request = [];
    if (channels_id.length > 0) {
      for (const channel_id of channels_id) {
        request.push(
          await this.getCustomFields("zendesk", channel_id, intent, false, true)
        );
      }
    }

    var use_custom_fields = false;
    request.forEach((el) => {
      if (!use_custom_fields) {
        use_custom_fields = {
          [el.channel_id]: el.custom_fields,
        };
      } else if (!use_custom_fields[el.channel_id]) {
        use_custom_fields[el.channel_id] = el.custom_fields;
      }
    });

    if (use_custom_fields) {
      this.setState({ use_custom_fields, intent: intent });
    }
  };

  getCustomFields = async (type, channel_id, intent, update, request) => {
    const { access, actualProject } = this.props.data;
    try {
      const obj = new IntegrationsAPI(actualProject.id, access, this.props);
      let custom_fields = await obj.custom_fields(channel_id);
      return await this.handleCustomFields(
        type,
        channel_id,
        custom_fields,
        intent,
        update,
        request
      );
    } catch (err) {
      this.props.dispatch(showNotify({ message: err, severity: "error" }));
    }
  };

  handleCustomFields = async (
    type,
    channel_id,
    custom_fields,
    intent,
    update,
    request
  ) => {
    var { use_custom_fields } = this.state;
    if (!update) {
      intent.parameters.map((parameter, i) => {
        var zendesk = parameter.zendesk_ticket_field,
          element_custom_field = returnElementFromArray(
            zendesk?.id,
            custom_fields,
            "id"
          );

        if (zendesk?.id && zendesk.desk_id === channel_id) {
          zendesk = {
            id: zendesk.id,
            type: zendesk.type,
            title: `${element_custom_field?.title} ${
              element_custom_field?.regexp_for_validation
                ? `(${element_custom_field.regexp_for_validation})`
                : ""
            }`,
            equivalences: zendesk.equivalences,
            desk_id: zendesk.desk_id,
            custom_field_options:
              element_custom_field?.custom_field_options ?? [],
            custom_field_options_actual:
              element_custom_field?.custom_field_options
                ? JSON.parse(
                    JSON.stringify(element_custom_field?.custom_field_options)
                  )
                : [],
          };

          zendesk.equivalences.map((equivalence, equivalence_i) => {
            zendesk.custom_field_options_actual.map((custom, custom_i) => {
              if (equivalence.value === custom.value) {
                zendesk.equivalences[equivalence_i].title = custom.name;
                zendesk.custom_field_options_actual.splice(custom_i, 1);
              }
            });
          });

          intent.parameters[i].zendesk_ticket_field = zendesk;
        } else {
          zendesk = null;
        }
      });

      (intent.extensions.variables_desk || []).map((variable, i) => {
        var zendesk = variable.zendesk_ticket_field,
          element_custom_field = returnElementFromArray(
            zendesk?.id,
            custom_fields,
            "id"
          );

        if (zendesk?.id && zendesk.desk_id === channel_id) {
          zendesk = {
            id: zendesk.id,
            type: zendesk.type,
            title: `${element_custom_field?.title} ${
              element_custom_field?.regexp_for_validation
                ? `(${element_custom_field.regexp_for_validation})`
                : ""
            }`,
            equivalences: zendesk.equivalences,
            desk_id: zendesk.desk_id,
            custom_field_options:
              element_custom_field?.custom_field_options ?? [],
            custom_field_options_actual:
              element_custom_field?.custom_field_options
                ? JSON.parse(
                    JSON.stringify(element_custom_field?.custom_field_options)
                  )
                : [],
          };

          zendesk.equivalences.map((equivalence, equivalence_i) => {
            zendesk.custom_field_options_actual.map((custom, custom_i) => {
              if (equivalence.value === custom.value) {
                zendesk.equivalences[equivalence_i].title = custom.name;
                zendesk.custom_field_options_actual.splice(custom_i, 1);
              }
            });
          });

          intent.extensions.variables_desk[i].zendesk_ticket_field = zendesk;
        } else {
          zendesk = null;
        }
      });
    }

    if (request) {
      return { custom_fields, channel_id };
    } else {
      if (!use_custom_fields) {
        use_custom_fields = {
          [channel_id]: custom_fields,
        };
      } else {
        use_custom_fields[channel_id] = custom_fields;
      }

      this.setState({ use_custom_fields: use_custom_fields, intent: intent });
        this.handleVariables(intent); 
    }
  };

  getUsers = async () => {
    get_users(this.props).then((users) => {
      this.setState({ users: users });
    });
  };

  updateIntent = (new_intent) => {
    this.setState({ intent: new_intent });
  };

  updateContexts = (new_contexts) => {
    this.setState({ contexts: new_contexts });
  };

  updateParams = (new_param) => {
    const { params } = this.state;
    params.unshift(`@${new_param.name}`);
    this.setState({ params: params });
  };

  updateVariables = (new_variables) => {
    this.setState({ variables: new_variables });
  };

  updateVariablesDesk = (new_variables_desk) => {
    this.setState({ variables_desk: new_variables_desk });
  };

  updateElement = (e, type, type_of, i) => {
    const { intent } = this.state;
    const obj = new ElementsHandleV2(
      intent,
      e?.target?.value,
      type,
      type_of,
      i
    );
    var res = obj.handleElement();

    if (type_of === "delete") {
      switch (type) {
        case "responses":
        case "phrases":
          this.handleLimits(type, "delete", res);
          break;
        case "extension":
          this.setState({ intent: res, variables: [] });
          break;
        case "conditional_responses":
        case "parameters":
          this.setState({ intent: res });
          break;
        default:
      }
    } else {
      if (res.is_default && res.phrases.length > 0) {
        res.phrases = [];
        this.setState({ limits: this.props.data.limits, intent: res });
      } else {
        this.setState({ intent: res });
      }
    }

    if (type.includes("extension")) {
      e.stopPropagation();
    }
  };

  handleLimits = (type, action, intent) => {
    const { limits } = this.state;
    switch (action) {
      case "delete":
        switch (type) {
          case "responses":
          case "phrases":
            limits[type].total = limits[type].total - 1;
            limits.intents_create[`intents_${type}_total`] =
              limits.intents_create[`intents_${type}_total`] !== 0 &&
              limits.intents_create[`intents_${type}_total`] - 1;
            break;
          default:
        }
        break;
      case "add":
        break;

      default:
    }

    this.setState({ limits: limits, intent: intent });
  };

  handleIntent = async (type) => {
    const { intent, variables } = this.state;
    const extensionsExist = intent.extensions && intent.extensions.url;
    const initialPayload = intent.extensions.body?.payload;
    let updatedPayload;

    if (initialPayload && typeof initialPayload === "string") {
      updatedPayload = initialPayload.replace(
        /:\s*(\$\.\w+(\.\w+)*\b)/g,

        ': "$1"'
      );
    } else {
      updatedPayload = initialPayload;
    }

    if (!validExtensions(variables, this.props.dispatch)) {
      return;
    }

    const validVariables = variables.filter(
      (variable) => variable && variable.variable
    );

    if (extensionsExist && !intent.extensions.timeout) {
      intent.extensions.timeout = 15;
    }

    if (intent.extensions.method) {
      intent.extensions.variables = this.handleAddVariables(variables);
      intent.extensions.equivalences = this.handleAddEquivalences(variables);
    }

    var value = true,
      body = intent.extensions.body?.payload;
    const payload = updatedPayload && updatedPayload ? updatedPayload : body;

    if (
      intent.extensions.body?.type === "application/json" &&
      payload &&
      !isJsonString(payload)
    ) {
      value = false;
    }

    if (
      intent.extensions.body?.type === "application/json" &&
      payload &&
      !isJsonString(payload)
    ) {
      value = false;
    }
    if (!value) {
      var data = {
        message: "extension_body_has_an_invalid_value",
        severity: "error",
      };
      this.props.dispatch(showNotify(data));
    } else if (handlePhrasesParams(intent, this.props.dispatch)) {
      var send_intent = JSON.parse(JSON.stringify(intent));

      send_intent.parameters.forEach((param, i) => {
        if (param.zendesk_ticket_field) {
          delete send_intent.parameters[i].zendesk_ticket_field
            .custom_field_options;
          delete send_intent.parameters[i].zendesk_ticket_field
            .custom_field_options_actual;
        }

        if (
          param.zendesk_ticket_field &&
          (param.zendesk_ticket_field.desk_id === null ||
            !param.zendesk_ticket_field.id)
        ) {
          send_intent.parameters[i].zendesk_ticket_field = {};
        }
      });

      var zendesk_ticket_status = send_intent.desks.info.zendesk_ticket_status,
        zendesk_mode = send_intent.desks.info.zendesk_mode,
        centridesk_ticket_status =
          send_intent.desks.info.centridesk_ticket_status,
        centridesk_mode = send_intent.desks.info.centridesk_mode;

      if (send_intent.desks.name === "zendesk_full") {
        send_intent.desks.info.ticket_status = zendesk_ticket_status;
        send_intent.desks.info.mode = zendesk_mode;
      } else if (send_intent.desks.name === "centridesk") {
        send_intent.desks.info.ticket_status = centridesk_ticket_status;
        send_intent.desks.info.mode = centridesk_mode;
      }

      if (send_intent.desks.name === "gpt-3") {
        send_intent.desks.info = {
          max_tokens: intent.desks.info.max_tokens,
          max_interactions: intent.desks.info.max_interactions,
        };
      } else {
        delete send_intent.desks.info.max_tokens;
        delete send_intent.desks.info.max_interactions;
      }

      if (send_intent.conditional_responses.length > 0) {
        send_intent.conditional_responses.forEach((conditional) => {
          delete conditional.param0;
        });
      }

      delete send_intent.desks.info.zendesk_ticket_status;
      delete send_intent.desks.info.centridesk_ticket_status;
      delete send_intent.desks.info.zendesk_mode;
      delete send_intent.desks.info.centridesk_mode;

      if (
        send_intent.extensions.variables &&
        send_intent.extensions.variables_desk
      ) {
        const validIds = validVariables;
        send_intent.extensions.variables_desk.forEach((variableDesk, index) => {
          if (send_intent.extensions.variables[index] === variableDesk.name) {
            variableDesk.name = send_intent.extensions.variables[index];
          }
        });
        const allowedVariableNames = send_intent.extensions.variables.map(
          (variable) => variable
        );
        send_intent.extensions.variables_desk = parseVariablesDeskEdit(
          send_intent.extensions.variables_desk,
          allowedVariableNames,
          validIds
        );
      }

      if (send_intent.extensions.auth) {
        delete send_intent.extensions.auth.access_key_input;
      }

      if (!send_intent.desks?.name) {
        send_intent.desks = null;
      }

      if (
        send_intent.desks?.name === "zendesk_full" &&
        !send_intent.desks.info.desk_id
      ) {
        this.props.dispatch(
          showNotify({ message: "no_desk_id_intent", severity: "error" })
        );
        return;
      }

      if (type === "create") {
        this.cloneIntent(send_intent);
      } else {
        this.saveIntent(send_intent);
      }
    }
  };
  saveIntent = async (send_intent) => {
    const { actualProject, access } = this.props.data;
    try {
      const obj = new IntentsAPI(
        actualProject.id,
        access,
        this.props,
        send_intent
      );
      await obj.put_intent();

      this.handleAmplitude("Intent updated");

      this.props.dispatch(
        showNotify({ message: "intents", type: "edit", severity: "success" })
      );
    } catch (err) {
      this.props.dispatch(showNotify({ message: err, severity: "error" }));
    }
  };

  cloneIntent = async (send_intent) => {
    const { clone_name } = this.state;
    const { actualProject, access } = this.props.data;

    delete send_intent.id;
    send_intent.name = clone_name;
    try {
      const obj = new IntentsAPI(
        actualProject.id,
        access,
        this.props,
        send_intent
      );
      await obj.post_intent();

      this.handleAmplitude("Intent created");

      this.setState({ clone_name: "", open: false });

      this.props.dispatch(
        showNotify({ message: "intents", type: "create", severity: "success" })
      );
    } catch (err) {
      this.props.dispatch(showNotify({ message: err, severity: "error" }));
    }
  };

  handleAddVariables = () => {
    var obj = [],
      variables = this.state.variables;

    variables.forEach((el) => {
      if (el.variable) {
        obj.push(`$.${el.variable}`);
      }
    });

    return obj;
  };

  handleAddEquivalences = () => {
    var obj = {},
      variables = this.state.variables,
      value = {};
    variables.forEach((item, i) => {
      if (item.variable) {
        var element = `$.${item.variable}`;
        if (value) {
          obj = { [element]: this.handleEquivalence(item.equivalence), ...obj };
          this.state.variables[i].error = false;
          value = obj;
        } else {
          this.state.variables[i].error = true;
          value = false;

          this.props.dispatch(
            showNotify({
              message: "extension_equivalences_has_an_invalid_value",
              severity: "error",
            })
          );
        }
      } else {
        this.state.variables[i].error = true;
        value = false;

        this.props.dispatch(
          showNotify({
            message: "extension_variable_can_not_be_an_empty_string",
            severity: "error",
          })
        );
      }
    });

    this.setState({ variables: this.state.variables });

    return value;
  };

  handleEquivalence = (equivalences) => {
    var obj = [];
    equivalences.forEach((equivalence) => {
      obj = { [equivalence.key]: equivalence.value, ...obj };
    });

    return obj;
  };

  returnToList = () => {
    const { location, history, handleTabs } = this.props;
    history.push(`${location.pathname}?tab=intents`);
    handleTabs();
  };

  handleTags = (e, type, elementType, chipToDelete, i) => {
    const { settings, intent } = this.state;
    var element_to_update;

    switch (elementType) {
      case "tags":
        element_to_update = intent?.tags ? intent.tags : [];
        break;
      default:
    }

    var tag = e.target.value;
    switch (type) {
      case "add":
        if (tag.match(/^[a-zA-Z0-9_\/]*$/)) {
          settings[elementType] = tag;
          this.setState({ settings: settings });
        }
        break;
      case "delete":
        element_to_update.forEach((el) => {
          if (el === chipToDelete) {
            element_to_update.splice(i, 1);
          }
        });
        this.setState({ intent: intent });
        break;
      case "enter":
        element_to_update.push(tag);
        settings[elementType] = "";
        this.setState({ intent: intent, settings: settings });
        break;
      default:
    }
  };
  render() {
    const {
      contexts,
      limits,
      intent,
      clone_name,
      params,
      variables,
      use_custom_fields,
      zendesk_groups,
      switchboard,
      integrations,
      events,
      open,
      settings,
    } = this.state;
    const { isSending, plan } = this.props.data;
    const { t } = this.props;
    return intent && contexts ? (
      <React.Fragment>
        <div>
          <div className={"cen_card sticky"}>
            <div className={"header"}>
              <div className={"header_content"}>
                <div className={"header_title"}>
                  <span className={"title"}>
                    {t("common:intents.title_edit")}
                  </span>
                </div>
              </div>
              <div className={"header_actions"}>
                <Button
                  type={"cancel"}
                  onClick={(e) => this.returnToList(e)}
                  text={t("common:intents.buttons.cancel")}
                />
                {isSending ? (
                  <React.Fragment>
                    <Button
                      className={"btn_icon"}
                      type={"primary"}
                      disabled
                      text={<CircularProgress size={21} />}
                    />
                    <Button
                      type={"primary"}
                      disabled
                      text={<CircularProgress size={21} />}
                    />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {!intent.is_default && (
                      <Button
                        className={"btn_icon"}
                        type={"primary"}
                        onClick={() => {
                          this.setState({ open: true });
                        }}
                        text={cloneSvg()}
                      />
                    )}
                    <Button
                      type={"primary"}
                      onClick={() => {
                        this.handleIntent("edit");
                      }}
                      text={t("common:intents.buttons.save")}
                    />
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
          {/* INFO */}
          <div className={"cen_card intents__content"}>
            <div className={"content column_content"}>
              <div className={"intents__elements"}>
                <div className={"intents__elements_column"}>
                  <div>
                    {/* INTENT NAME */}
                    <div className={"intents__element"}>
                      <div className={"intents__element_header"}>
                        <span>{t("common:intents.name.title")}</span>
                      </div>
                      <Input
                        id={"intent-name"}
                        placeholder={t("intents.name.placeholder")}
                        value={intent.name}
                        onChange={(e) => {
                          this.updateElement(e, "name", "string");
                        }}
                      />
                    </div>
                    <Divider />
                    {/* INTENT DESCRIPTION */}
                    <div className={"intents__element"}>
                      <div className={"intents__element_header"}>
                        <span>{t("common:intents.descript.title")}</span>
                      </div>
                      <Input
                        id={"intent-description"}
                        placeholder={t("intents.descript.placeholder")}
                        multiline
                        inputProps={{ maxLength: 4096 }}
                        value={intent.description}
                        onChange={(e) => {
                          this.updateElement(e, "description", "string");
                        }}
                      />
                    </div>
                  </div>
                  <Divider className={"intents__elements_column_divider"} />
                  {/* INTENT SWITCH */}
                  <div className={"intents__element grid"}>
                    <div className={"intents__element_header"}>
                      <span>{t("common:intents.options.title")}</span>
                    </div>
                    {/* IS DEFAULT */}
                    <div className={"elements__switch"}>
                      <span>
                        {t("common:intents.options.switch.is_default.title")}{" "}
                        <Tooltip
                          text={t(
                            "common:intents.options.switch.is_default.description"
                          )}
                        />{" "}
                      </span>
                      <Switch
                        checked={intent.is_default ? intent.is_default : false}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ACCORDIONS */}
          <div className={"cen_card intents__content"}>
            <div className={"header"}>
              <div className={"header_content"}>
                <div className={"header_title"}>
                  <span className={"title"}>
                    {t("common:intents.config.title")}
                  </span>
                </div>
              </div>
            </div>
            <div className={"content"} style={{ overflow: "visible" }}>
              <div className={"intents__elements"}>
                <div className={"intents__element grid"}>
                  <div className={"elements__accordion"}>
                    {/* PARAMS */}
                    {isPlanAllowed(plan.plan.name) && (
                      <AccordionIntents
                        expanded
                        intent={JSON.parse(JSON.stringify(intent))}
                        type={"params"}
                        params={params}
                        updateElement={this.updateElement}
                        updateIntent={this.updateIntent}
                        updateParams={this.updateParams}
                        integrations={integrations}
                        getCustomFields={this.getCustomFields}
                        use_custom_fields={
                          use_custom_fields
                            ? JSON.parse(JSON.stringify(use_custom_fields))
                            : null
                        }
                      />
                    )}
                    {/* PHRASES */}
                    <AccordionIntents
                      contexts={JSON.parse(JSON.stringify(contexts))}
                      expanded
                      limits={limits}
                      intent={JSON.parse(JSON.stringify(intent))}
                      type={"phrases"}
                      updateElement={this.updateElement}
                      updateIntent={this.updateIntent}
                      updateContexts={this.updateContexts}
                    />
                    {/* EXTENSIONS */}
                    {isPlanAllowed(plan.plan.name) && (
                      <AccordionIntents
                        expanded
                        intent={JSON.parse(JSON.stringify(intent))}
                        type={"extensions"}
                        params={params}
                        updateElement={this.updateElement}
                        updateIntent={this.updateIntent}
                        updateVariables={this.updateVariables}
                        updateVariablesDesk={this.updateVariablesDesk}
                        variables={variables}
                        updateParams={this.updateParams}
                        getCustomFields={this.getCustomFields}
                        integrations={integrations}
                        use_custom_fields={
                          use_custom_fields
                            ? JSON.parse(JSON.stringify(use_custom_fields))
                            : null
                        }
                      />
                    )}
                    {/* RESPONSES */}
                    <AccordionIntents
                      contexts={JSON.parse(JSON.stringify(contexts))}
                      expanded
                      limits={limits}
                      intent={JSON.parse(JSON.stringify(intent))}
                      type={"responses"}
                      events={events}
                      updateElement={this.updateElement}
                      updateIntent={this.updateIntent}
                      updateContexts={this.updateContexts}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* INTEGRATIONS */}
          {integrations && (
            <div className={"cen_card intents__content"}>
              <div className={"header"}>
                <div className={"header_content"}>
                  <div className={"header_title"}>
                    <span className={"title"}>
                      {t("common:intents.integrations.title")}
                    </span>
                  </div>
                </div>
              </div>

              <div className={"content"}>
                <div
                  className={`elements__integrations ${
                    integrations.some(
                      (integration) =>
                        integration.channel !== "zendesk_full" ||
                        integration.channel !== "sunco"
                    )
                      ? "elements__integrations--no-grid"
                      : ""
                  }`}
                >
                  {integrations.some(
                    (integration) =>
                      integration.channel === "zendesk_full" ||
                      integration.channel === "sunco"
                  ) && (
                    <div>
                      <ElementsCustom
                        t={t}
                        type={"zendesk"}
                        input_type={"input_with_tags"}
                        element_type={"tags"}
                        element={settings.tags}
                        elements={intent.tags ? intent.tags : []}
                        updateElement={this.handleTags}
                      />
                    </div>
                  )}
                </div>

                <Integrations
                  zendesk_groups={zendesk_groups}
                  intent={JSON.parse(JSON.stringify(intent))}
                  switchboard={switchboard}
                  integrations={integrations}
                  updateIntent={this.updateIntent}
                  getGroups={this.getGroups}
                />
              </div>
            </div>
          )}
          {/* POPUP */}
          <PopUp
            open={open}
            handleClose={() => this.setState({ open: false })}
            t={t}
            width={400}
            content={
              <div className={"intents__popup"}>
                <div className={"intents__popup_header"}>
                  <span>{t("common:intents.buttons.clone.title")}</span>
                </div>
                <Divider />
                <div className={"intents__popup_content"}>
                  <span> {t("common:intents.buttons.clone.name.title")} </span>
                  <Input
                    placeholder={t(
                      "common:intents.buttons.clone.name.placeholder"
                    )}
                    value={clone_name}
                    onChange={(e) => {
                      this.setState({ clone_name: e?.target?.value });
                    }}
                  />
                </div>
              </div>
            }
            buttons={
              isSending ? (
                <Button
                  type={"primary"}
                  text={<CircularProgress size={21} />}
                />
              ) : (
                <Button
                  type={"success"}
                  disabled={!clone_name}
                  onClick={() => this.handleIntent("create")}
                  text={t("common:intents.buttons.clone.button")}
                />
              )
            }
          />
        </div>
      </React.Fragment>
    ) : (
      <div className="card">
        <div className="loading-data">
          <CircularProgress color="primary" size={70} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state,
  };
};

const connect_intent_edit = connect(mapStateToProps)(IntentEdit);

export default withTranslation(["commons", "common"])(
  withRouter(connect_intent_edit)
);
