import exportFromJSON from "export-from-json";

export const downloadCSV = (data, fileName) => {
  if (data?.length) {
    exportFromJSON({
      data,
      fileName,
      exportType: "csv"
    });
  }
};
