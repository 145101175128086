// React
import React, { Component } from "react";
import { connect } from "react-redux";
// Routing
import { withRouter } from "react-router-dom";
// Translations
import { withTranslation } from "react-i18next";
import enLocale from "date-fns/locale/en-US";
import esLocale from "date-fns/locale/es";
import ptLocale from "date-fns/locale/pt";
// Styles
import "views/Campaigns/styles/index.scss";
// Date
import DateFnsUtils from "@date-io/date-fns";
import "date-fns";
// Vendor
import { showNotify, updateCampaigns } from "vendor/application/disptach";
// Helpers
import { getWhatsappPhone } from "@helpers";
// Components
import Table from "views/Campaigns/components/Table";
import { CampaignsAPI } from "views/Campaigns/infrastructure";
import { Search, SelectSimple } from "@components/Input";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
// Amplitude API
import { svgCalendar } from "assets";
import { format } from "date-fns";

const localeMap = {
  en: enLocale,
  es: esLocale,
  pt: ptLocale,
};

class CampaignsList extends Component {
  constructor(props) {
    super(props);
    var date = new Date();
    this.state = {
      groups: [],
      all_groups: false,
      page: 0,
      max: 0,
      status_filter: "all",
      name_filter: "",
      send_date: {
        startDate: new Date(date.setMonth(date.getMonth() - 12)),
        endDate: new Date(),
      },
      open_start: false,
      open_end: false,
      languages: false,
      whatsapp_phone: false,
      templates: false,
      is_loading: true,
    };
  }

  componentDidMount = () => {
    this.handleProjectID();
  };

  handleProjectID = (e) => {
    const { plan } = this.props.data;
    var whatsapp_phone;
    if (!e) {
      whatsapp_phone = plan?.whatsapp_phones[0];
    } else {
      whatsapp_phone = e;
    }

    this.getGroups(true, whatsapp_phone.project_id);
    this.setState({ whatsapp_phone: whatsapp_phone });
  };

  getGroups = async (reset_filters, project_id) => {
    const { access, campaigns } = this.props.data;
    const { send_date } = this.state;
    this.setState({ is_loading: true });
    try {
      let obj = new CampaignsAPI(project_id, access, this.props);
      const filters_date = {
        start: format(new Date(send_date.startDate), "yyyy-MM-dd"),
        end: format(new Date(send_date.endDate), "yyyy-MM-dd"),
      };
      let res = await obj.get_campaigns_pagination(filters_date, reset_filters);
      const { total_pages, current_page } = res;
      if (
        res.campaigns.length === 0 &&
        campaigns.pagination.page !== 1 &&
        !reset_filters
      ) {
        this.getGroups(true, project_id);
        this.setState({ is_loading: false });
      } else {
        if (reset_filters) {
          campaigns.pagination.page = 1;
          this.props.dispatch(updateCampaigns(campaigns));
        }
        res = res.campaigns && res.campaigns.reverse();
        var array = JSON.parse(JSON.stringify(res)),
          arr = [];

        while (array.length) {
          arr.push(array.splice(0, 10));
        }
        this.setState({
          groups: arr.flat(),
          all_groups: res.campaigns ? res.campaigns : [],
          max: total_pages,
          page: current_page,
          is_loading: false,
        });
      }
    } catch (err) {
      this.setState({ is_loading: false });
      this.props.dispatch(showNotify({ message: err, severity: "error" }));
    }
  };

  selectPagination = (e) => {
    const { campaigns } = this.props.data;
    campaigns.pagination.interval = e;
    campaigns.pagination.page = 1;
    this.props.dispatch(updateCampaigns(campaigns));
    this.getGroups(true, this.state.whatsapp_phone.project_id);
  };

  getDateFormat = (date) => {
    var m = date.getMonth(),
      y = date.getFullYear(),
      d = date.getDate();

    return `${y}-${m + 1}-${d}`;
  };

  getCentripushLimits = async (account_id) => {
    const { access } = this.props.data;
    try {
      let obj = new CampaignsAPI(account_id, access, this.props);
      let res = await obj.get_centripush_limits();

      this.setState({ centripush_limits: res });
    } catch (err) {
      this.props.dispatch(showNotify({ message: err, severity: "error" }));
    }
  };

  handleSelectStart = (start) => {
    const end = this.state.send_date.endDate;
  
    const updatedEnd = start > end ? start : end;
    this.setState(
      { send_date: { startDate: start, endDate: updatedEnd } },
      () => this.getGroups(true, this.state.whatsapp_phone.project_id)
    );
  };
  
  handleSelectEnd = (end) => {
    const start = this.state.send_date.startDate;
  
    const updatedStart = end < start ? end : start;
    this.setState(
      { send_date: { startDate: updatedStart, endDate: end } },
      () => this.getGroups(true, this.state.whatsapp_phone.project_id)
    );
  };

  getDate = (type, send) => {
    const { send_date } = this.state;
    var m = send_date[type].getMonth(),
      y = send_date[type].getFullYear();

    if (send) {
      return `${y}-${m + 1}-1`;
    }
    return `${m + 1} / ${y}`;
  };

  handlePagination = (e, next_page) => {
    const { campaigns } = this.props.data;
    if (e) {
      campaigns.pagination.page = next_page;

      this.props.dispatch(updateCampaigns(campaigns));
      this.getGroups(false, this.state.whatsapp_phone.project_id);
    }
  };

  handleFilter = (e, type) => {
    var { all_groups, name_filter, send_date } = this.state;
    const { campaigns } = this.props.data;
    switch (type) {
      case "search":
        name_filter = e.target.value;
        break;
      case "status":
        campaigns.status.name = e;
        this.props.dispatch(updateCampaigns(campaigns));
        this.getGroups(true, this.state.whatsapp_phone.project_id);
        break;
      case "send_date":
        send_date = e;
        break;
      default:
        break;
    }

    var groups = name_filter
      ? all_groups.filter((i) => i.name.includes(name_filter))
      : all_groups;
    groups =
      campaigns.status.name !== "all"
        ? groups.filter((i) => i.status === campaigns.status.name)
        : groups;

    groups = groups.filter((i) => {
      var date = i.send_date || {};
      date = new Date(date);
      return date >= send_date.startDate && date <= send_date.endDate;
    });

    var array = JSON.parse(JSON.stringify(groups)),
      arr = [];

    while (array.length) {
      arr.push(array.splice(0, 10));
    }

    this.setState({
      groups: arr,
      status_filter: campaigns.status.name,
      name_filter: name_filter,
      send_date: send_date
    });
  };

  handleRoute = (item) => {
    const { whatsapp_phone } = this.state;
    const { setQuery } = this.props;

    setQuery(null, item.campaign_id, whatsapp_phone.project_id);
  };

  openDatePicker = (type) => {
    this.setState({ [type]: !this.state[type] });
  };

  render() {
    const { t } = this.props;
    const { actualLayout, plan} = this.props.data;
    const {
      groups,
      name_filter,
      send_date,
      open_start,
      open_end,
      whatsapp_phone,
      max,
      page,
    } = this.state;

    const groupsItems =
      groups.length > 0
        ? groups.map((item) => ({
            ...item,
            send_date: item.send_date
              ? format(new Date(item.send_date), "yyyy-MM-dd")
              : "",
            created_at: item.created_at
              ? format(new Date(item.created_at), "yyyy-MM-dd")
              : "",
            status: item.status,
            totals: item.totals,
            name: item.name,
            template_name: item.template_name,
          }))
        : [];

    const statusMapping = {
      all: t("common:card.campaigns.status.all"),
      finished: t("common:card.campaigns.status.finished"),
      rejected: t("common:card.campaigns.status.rejected"),
      in_progress: t("common:card.campaigns.status.in_progress"),
      requested: t("common:card.campaigns.status.requested"),
    };

    const selectedStatus =
      statusMapping[this.props.data.campaigns.status.name] ||
      this.props.data.campaigns.status.name;
    return (
      <React.Fragment>
        <div className="centribal_card">
          <div className={"campaigns_header"}>
            <div className={"campaigns_header_title"}>
              <span className={"title"}>
                {t(
                  `common:card.content.${actualLayout.layout}.campaigns.header_title`
                )}
              </span>
            </div>
            <div className={"campaigns_header_actions"}>
              {whatsapp_phone?.phone && (
                <SelectSimple
                  label={t(
                    `common:card.content.${actualLayout.layout}.campaigns.filters.whatsapp_phone`
                  )}
                  valueSelected={getWhatsappPhone(whatsapp_phone)}
                  items={plan?.whatsapp_phones}
                  onSelect={(e) => this.handleProjectID(e)}
                  type={"whatsapp_phone"}
                  className={"select_secondary templates_whatsapp_phones"}
                  value={"phone"}
                  t={t}
                />
              )}
              <div className={"header_actions"}>
                <SelectSimple
                  label={t("common:card.campaigns.selector_pagination")}
                  maxWidth={200}
                  valueSelected={this.props.data.campaigns.pagination.interval}
                  items={[10, 25, 50]}
                  onSelect={(e) => this.selectPagination(e)}
                  className={"select_secondary templates_status"}
                  t={t}
                />
              </div>

              <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                locale={localeMap[this.props.i18n.language]}
              >
                <div
                  className="platform_date_picker_groups"
                  onClick={() => this.openDatePicker("open_start")}
                >
                  <span>
                    {t(
                      `common:card.content.${actualLayout.layout}.campaigns.filters.send_date_start`
                    )}
                  </span>
                  <div>
                    <span>{this.getDate("startDate")}</span>
                    <span>{svgCalendar()}</span>
                  </div>
                </div>
                <DatePicker
                  value={send_date.startDate}
                  onChange={this.handleSelectStart}
                  animateYearScrolling
                  className={"date_picker_groups"}
                  views={["year", "month"]}
                  openTo={"month"}
                  autoOk
                  onClose={() => this.openDatePicker("open_start")}
                  open={open_start}
                />
                <div
                  className="platform_date_picker_groups"
                  onClick={() => this.openDatePicker("open_end")}
                >
                  <span>
                    {t(
                      `common:card.content.${actualLayout.layout}.campaigns.filters.send_date_end`
                    )}
                  </span>
                  <div>
                    <span>{this.getDate("endDate")}</span>
                    <span>{svgCalendar()}</span>
                  </div>
                </div>
                <DatePicker
                  value={send_date.endDate}
                  onChange={this.handleSelectEnd}
                  animateYearScrolling
                  className={"date_picker_groups"}
                  views={["year", "month"]}
                  openTo={"month"}
                  autoOk
                  onClose={() => this.openDatePicker("open_end")}
                  open={open_end}
                />
              </MuiPickersUtilsProvider>
              <SelectSimple
                label={t(
                  `common:card.content.${actualLayout.layout}.campaigns.filters.status`
                )}
                valueSelected={selectedStatus}
                items={[
                  "all",
                  "finished",
                  "rejected",
                  "in_progress",
                  "requested",
                ]}
                onSelect={(e) => this.handleFilter(e, "status")}
                placeholder={t(
                  `common:card.content.${actualLayout.layout}.campaigns.filters.status`
                )}
                type={"status"}
                className={"select_secondary templates_status"}
                t={t}
              />
              <Search
                label={t(
                  `common:card.content.${actualLayout.layout}.campaigns.filters.name`
                )}
                t={t}
                placeholder={t(
                  `common:card.content.${actualLayout.layout}.campaigns.filters.name`
                )}
                onSearch={this.handleFilter}
                value={name_filter}
              />
            </div>
          </div>
          <div
            className={
              "campaigns_container_elements campaigns_container_elements_alone "
            }
          >
            <div className={"campaigns_container_elements_content"}>
              <Table
                conditional={groups}
                items={groupsItems}
                type={"history.campaigns"}
                onClick={this.handleRoute}
                handlePagination={this.handlePagination}
                page={page}
                max={max}
                header={[
                  "name",
                  "status",
                  "template_name",
                  "totals",
                  "send_date",
                  "created_at"
                ]}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state
  };
};

const campaigns_list = connect(mapStateToProps)(CampaignsList);

export default withTranslation(["commons", "common"])(
  withRouter(campaigns_list)
);
